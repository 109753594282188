<div class="row">
  <div [ngClass]="headerTitle? 'col-4':'col-8'">
    <div class="content-header my-2 ml-1">
      <h4 *ngIf="headerTitle" class="font-weight-bold text-secondary mt-2 m-0">{{headerTitle}}</h4>
      <h4 *ngIf="!headerTitle" class="text-dark font-weight-bold ">{{headerTitleModal}}</h4>
    </div>
  </div>
  <div [ngClass]="headerTitle?'col-8':'col-4'">
    <ng-content></ng-content>
  </div>
</div>