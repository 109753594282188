import { RouteInfo } from './vertical-menu.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/device-status', title: 'Integration Status', icon: 'ft-plus-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },

  {
    path: '/core-entities', title: 'Core Entities', icon: 'ft-settings', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      // {
      //   path: '/core-entities/company', title: 'Company', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isExternalLink: false,
      //   submenu: []
      // },
      // {
      //   path: '/core-entities/department', title: 'Department', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isExternalLink: false,
      //   submenu: []
      // },
      // {
      //   path: '/core-entities/operating-unit', title: 'Operating Unit', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isExternalLink: false,
      //   submenu: []
      // },
      // {
      //   path: '/eztrack-settings/company', title: 'Company', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isExternalLink: false,
      //   submenu: []
      // },
      // {
      //   path: '/eztrack-settings/department', title: 'Department', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isExternalLink: false,
      //   submenu: []
      // },
      // {
      //   path: '/eztrack-settings/operating-unit', title: 'Operating Unit', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isExternalLink: false,
      //   submenu: []
      // },
      {
        path: '/core-entities/partners', title: 'Partners', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: []
      },
      {
        path: '/core-entities/locations', title: 'Locations', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/core-entities/asset-management', title: 'Assets Management', icon: 'ft-truck', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
          {
            path: '/core-entities/asset-management/asset-class', title: 'Asset Class', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
            path: '/core-entities/asset-management/asset-types', title: 'Asset Types', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
            path: '/core-entities/asset-management/asset', title: 'Assets', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
        ]
      },
      {
        path: '/core-entities/device-management', title: 'Device Management', icon: 'fas fa-tractor', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
          {
            path: '/core-entities/device-management/report-configuration', title: 'Report Configurations', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
            path: '/core-entities/device-management/providers', title: 'Providers', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
            path: '/core-entities/device-management/devices', title: 'Devices', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
            path: '/core-entities/device-management/device-type', title: 'Device Model', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
        ]
      },
      {
        path: '/core-entities/installed-devices', title: 'Installed Devices', icon: 'ft-arrow-right', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
    ]
  },

  {
    path: '/config-management', title: 'Config Management', icon: 'ft-aperture', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      {
        path: '/config-management/api-management', title: 'API Management', icon: 'ft-cpu', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false, 
        submenu: [
          {
            path: 'config-management/api-management/external-api', title: 'External API', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
            path: 'config-management/api-management/map-api', title: 'Map API', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
            path: 'config-management/api-management/comm-api', title: 'Comm API', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
        ]      
      },
      {
        path: '/config-management/globalization', title: 'Globalization', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/config-management/custom-field', title: 'Custom Field Management', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
    ]
  }, 

  {
    path: '/access-management', title: 'Access Management', icon: 'ft-user', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      {
        path: '/access-management/designations', title: 'Designations', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/access-management/employees', title: 'User Profiles', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/access-management/roles', title: 'Roles', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/access-management/users', title: 'Logins', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/access-management/operating-unit', title: 'Operating-Unit', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
      {
        path: '/access-management/division', title: 'Division', icon: 'ft-arrow-right', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: []
      },
    ]
  },

  

];
