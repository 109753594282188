// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
// environment.ts
// export const environment = {
//   production: false,
//   auth: {
//     clientID: 'my-local-clientid',
//     domain: 'local.eu.auth0.com',
//   },
//   APIURL: 'https://deve4scoreapi.azurewebsites.net/api/v1/',
//    AUTO_REFRESH_TIME_OUT: 300000, //5mint
//   AUTO_REFRESH_TIME_OUT_ezSettings: 900000, //15mint
// };
export const environment = {
  production: false,
  // APIURL: 'https://e4score-eztrack-api-dev.azurewebsites.net/api/v1/',
  APIURL: 'https://e4-eztrack-api.azurewebsites.net/api/v1/',
  APIURLV2: 'https://e4-eztrack-api.azurewebsites.net/api/v2/',
  AUTO_REFRESH_TIME_OUT: 300000, //5mint
  AUTO_REFRESH_TIME_OUT_ezSettings: 900000, //15mint
  // AUTO_REFRESH_TIME_OUT: 9000, //5mint
  // AUTO_REFRESH_TIME_OUT_ezSettings: 9000, //15mint
};
